import React from 'react'
import { Layout, Section, Container, EmailLink, SEO } from '../components'
import { makeContentStyle, textColumnStyle } from '../styles'

export const ContactPage = (props: any) => {
  const currPath = props.location.pathname
  const pageColor = 'grey'
  // This following line disables formatting linting
  /* eslint-disable prettier/prettier */
  return (
    <Layout currPath={currPath} pageColor={pageColor}>
      <SEO title="Kontakt" />
      <Section index={0} color={pageColor} renderBg={true}>
        <Container>
          <div css={[textColumnStyle, makeContentStyle(pageColor)]}>
            <h1>Kontakt</h1>
            <p>
              Bei Fragen zur Kampagne:
              <br />
              <EmailLink email="solidaritaet-verbindet@rote-hilfe.de" /> |{' '}
              <a
                href="https://www.solidaritaet-verbindet.de/solidaritaet-verbindet-pgp.asc"
                download
              >
                PGP-Key
              </a>
            </p>
            <p>
              Für die Bestellung von Kampagnenmaterial:
              <br />
              <EmailLink email="literaturvertrieb@rote-hilfe.de" /> |{' '}
              <a href="https://www.solidaritaet-verbindet.de/literaturvertrieb-pgp.asc" download>
                PGP-Key
              </a>
              <br />
              Tel: +49 (0)431 - 7 51 41
            </p>

            <p>
              Rote Hilfe e.V.
              <br />
              Lange-Geismar-Straße 3<br />
              37073 Göttingen
              <br />
            </p>

            <p>
              Tel.: +49 (0)551 - 7 70 80 08
              <br />
              Fax: +49 (0)551 - 7 70 80 09
            </p>

            <p>
              <EmailLink email="info@rote-hilfe.de" /> |{' '}
              <a href="https://www.rote-hilfe.de/81-infos/741-pgp-key" download>
                PGP-Key
              </a>
              <br />
              <EmailLink email="bundesvorstand@rote-hilfe.de" /> |{' '}
              <a href="https://www.rote-hilfe.de/81-infos/741-pgp-key" download>
                PGP-Key
              </a>
            </p>

            <p>Fingerprint: 9278 214D 4076 548C 51E9 5C30 EE18 1232 9D06 D5B1</p>

            <p>
              VR 201688 AG Göttingen
              <br />
              St.-Nr. 20/205/03160 FA Göttingen
            </p>

            <h2>Internetredaktion</h2>
            <p>
              <EmailLink email="internetredaktion@rote-hilfe.de" />
              <br />
              Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV und § 5 TMG: Anja Sommerfeld
            </p>
            <h2>Verschlüsselte Kommunikation</h2>
            <p>
              Die Rote Hilfe rät, e-Mail-Kommunikation grundsätzlich mit PGP zu verschlüsseln.
              PGP-Schlüssel zu den hier genannten Adressen sollten in der Regel auf den Keyservern
              zu finden sein. Wir bemühen uns, alle Schlüssel mit dem Schlüssel der
              Datenschutzgruppe der Roten Hilfe Heidelberg (Fingerprint 4FD3 B3EE 7FCE 9FFD EC75
              CAF9 4847 5F52 5C0C 5DB1; der Fingerprint findet sich in gedruckter Form unter den get
              connected-Artikeln in der Zeitung der Roten Hilfe) zu signieren, so dass sie
              zuverlässig verifiziert werden können.
            </p>
            <p>
              (
              <a href="https://www.rote-hilfe.de/81-infos/737-pgp-schluessel-von-rh-funktionen-und-ortsgruppen">
                Mehr Infos auf rote-hilfe.de
              </a>
              )
            </p>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default ContactPage
